import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginatedList } from "@app/models/common/paginated-list.class";
import { Tag, TagCreate } from "@app/models/network/tags.class";
import { environment } from "@environments/environment";

@Injectable()
export class NetworkService {
  private uri: string = `${environment.routes.apiEndpoint}/network`;
  constructor(private http: HttpClient) { }

  public getTags(
    pageSize = 10, pageIndex = 0,
    sortField = 'name', sortDescending = true
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;

    return this.http.get<PaginatedList<Tag>>(`${this.uri}/tags?${filters}`);
  }

  public createTag(tag: TagCreate) {
    return this.http.post<Tag>(`${this.uri}/tags`, tag);
  }

  public updateTag(tag: TagCreate, id: string) {
    return this.http.put<Tag>(`${this.uri}/tags/${id}`, tag);
  }

  public deleteTag(id: string) {
    return this.http.delete(`${this.uri}/tags/${id}`);
  }
}
